// Navbar.js

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import CommonLink from "@components/common-link"
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter, FiYoutube } from "react-icons/fi"
import { FaPinterestP } from "react-icons/fa"
const year = new Date().getFullYear()
const Logo = () => {
    return (
        <div>
            <Link to="/">
                <img
                    className="logo hide-off-desktop"
                    src="/images/logo-header.png"
                    srcSet="/images/logo-header-2x.png 2x"
                    alt="Sonderly logo"
                    width="198.5"
                    height="60"
                />
                <img
                    className="logo hide-on-desktop"
                    src="/images/logomark.svg"
                    width="40"
                    height="56"
                    alt="Sonderly logo"
                />
            </Link>
        </div>
    )
}

class Hamburger extends React.Component {
    constructor(props) {
        super(props)
        this.menu = props.menu
        this.copyright = props.copyright
        this.state = {
            checked: false,
        }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.setState({
            checked: !this.state.checked,
        })
    }

    render() {
        return (
            <div id="menuToggle">
                <input
                    type="checkbox"
                    checked={this.state.checked}
                    onChange={() => this.setState({ checked: !this.state.checked })}
                    onClick={this.handleClick}
                />
                <span></span>
                <span></span>
                <span></span>
                <div id="mobileMenu" className={this.state.checked ? "isopen" : ""}>
                    <Link to="/">
                    <img
                        className="logo"
                        src="/images/logo-header.png"
                        srcSet="/images/logo-header-2x.png 2x"
                        alt="Sonderly logo"
                        width="198.5"
                        height="60"
                    />
                </Link>
                    <ul className="l1">
                        {this.menu.map((item, i) => {
                            const link = item.primary.link
                            return (
                                <li
                                    key={`l1${i}`}
                                    className={item.items[0] && item.items[0].submenu_item_text && "hasChildren"}
                                >
                                    <CommonLink type={link.link_type} to={link.url} onClick={this.handleClick}>
                                        {item.primary.text}
                                    </CommonLink>
                                    {item.items.length > 0 && item.items[0].submenu_item_text && (
                                        <ul className="l2">
                                            {item.items.map((subitem, j) => (
                                                <li key={`l2${j}`}>
                                                    <CommonLink
                                                        type={subitem.submenu_item_link.link_type}
                                                        to={subitem.submenu_item_link.url}
                                                        onClick={this.handleClick}
                                                    >
                                                        {subitem.submenu_item_text}
                                                    </CommonLink>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                    <div className="bottom">
                        <ul className="socials">
                            <li>
                                <a href="https://www.facebook.com/sonderly.io/" title="Facebook" target="_blank">
                                    <FiFacebook />
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/sonderlyio" title="Twitter" target="_blank">
                                    <FiTwitter />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/sonderly.io/" title="Instagram" target="_blank">
                                    <FiInstagram />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/showcase/sonderly/" title="LinkedIn" target="_blank">
                                    <FiLinkedin />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtube.com/channel/UCdL4jIHNUJopMZCWQqAkX1A"
                                    title="youtube"
                                    target="_blank"
                                >
                                    <FiYoutube />
                                </a>
                            </li>
                            <li>
                                <a href="https://pin.it/2hdWmNY" title="pinterest" target="_blank">
                                    <FaPinterestP />
                                </a>
                            </li>
                        </ul>
                        <div className="copyright">
                            &copy; {year} <div dangerouslySetInnerHTML={{ __html: this.copyright.html }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Navbar extends React.Component {
    constructor(props) {
        super(props)
        this.menu = props.menu
        this.handleEnter = this.handleEnter.bind(this)
        this.handleLeave = this.handleLeave.bind(this)
    }

    handleEnter(event) {
        const el = event.currentTarget 
        const wrapper = el.getElementsByClassName('ul-wrapper')[0]
        const ul = wrapper.getElementsByTagName('ul')[0]
        wrapper.style.maxHeight = ul.offsetHeight + 'px'
        wrapper.style.maxWidth = ul.offsetWidth + 'px'
    }

    handleLeave(event) {
        const el = event.currentTarget 
        const wrapper = el.getElementsByClassName('ul-wrapper')[0]
        const ul = wrapper.getElementsByTagName('ul')[0]
        wrapper.style.maxHeight = '0px' 
        wrapper.style.maxWidth = '0px'
    }

    render() {
        return (
            <div>
                <ul id="primaryMenu" className="l1">
                    {this.menu.map((item, i) => {
                        const { text, link } = item.primary
                        const { url, link_type } = link
                        const hasChildren = item.items[0] && item.items[0].submenu_item_text
                        return (
                            <li key={`l1${i}`} className={hasChildren && "hasChildren"} onMouseEnter={hasChildren && this.handleEnter} onMouseLeave={hasChildren && this.handleLeave}>
                                {url ? (
                                    <CommonLink type={link_type} to={url} className="header-link l1-link">
                                        {text}
                                    </CommonLink>
                                ) : (
                                    <span className="header-link l1-link">{text}</span>
                                )}
                                {item.items.length > 0 && item.items[0].submenu_item_text && (
                                    <div className='ul-wrapper' style={{maxHeight:0, maxWidth:0}}>
                                        <ul className="l2">
                                            {item.items.map((subitem, j) => {
                                                return (
                                                    <li key={`l2${j}`}>
                                                        <CommonLink
                                                            type={subitem.submenu_item_link.link_type}
                                                            to={subitem.submenu_item_link.url}
                                                            className="header-link"
                                                        >
                                                            {subitem.submenu_item_text}
                                                        </CommonLink>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

const Lang = ({ currentUrl }) => {
    const locale = process.env.GATSBY_LOCALE
    const locale_host = process.env.GATSBY_LOCALE_LINK || ""
    const url = `${locale_host}${currentUrl}`
    //const flag =  ? "/images/french.svg" : "/images/english.svg"
    return (
        locale == null || locale === "en-us" ?
        <p className="language-switcher">
            EN <a href={url} className="lang" title="switch language">FR</a>
        </p>
        :
        <p className="language-switcher">
            <a href={url} className="lang" title="switch language">EN</a> FR
        </p>
    )
}
const Header = ({ location }) => {
    const data = useStaticQuery(graphql`
        query Header {
            prismicMenu {
                data {
                    login_text
                    login_link {
                        url
                    }
                    body {
                        ... on PrismicMenuBodyMenuItem {
                            id
                            items {
                                submenu_item_text
                                submenu_item_link {
                                    url
                                    uid
                                    type
                                    link_type
                                    lang
                                    target
                                }
                            }
                            primary {
                                text
                                link {
                                    url
                                    uid
                                    type
                                    target
                                    link_type
                                    lang
                                }
                            }
                        }
                    }
                    copyright {
                        html
                    }
                }
            }
        }
    `)
    const { login_text, login_link } = data.prismicMenu.data
    return (
        <div className="header">
            <div className="header-container">
                <div className="desktop">
                    <Logo />
                    <Navbar currentUrl={location.pathname} menu={data.prismicMenu.data.body} />
                    <a href={login_link.url} className="button">
                        {login_text}
                    </a>
                    <Lang currentUrl={location.pathname} />
                    <Hamburger menu={data.prismicMenu.data.body} copyright={data.prismicMenu.data.copyright} />
                </div>
                <div className="mobile"></div>
            </div>
        </div>
    )
}

export default Header
